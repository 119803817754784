import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import Simulation from '../Simulation';

const Hero = () => {
  return (
    <Box>
      <Box as="section" bg="gray.800" color="white" py="7.5rem">
        <Box
          maxW={{ base: 'xl', md: '5xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
        >
          <Box textAlign="center">
            <Heading
              as="h1"
              size="3xl"
              fontWeight="extrabold"
              maxW="48rem"
              mx="auto"
              lineHeight="1.2"
              letterSpacing="tight"
            >
              Window Film Simulation
            </Heading>
          </Box>
          <Simulation />
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
