import {
  AudiQ5CT05,
  AudiQ5CT20,
  AudiQ5CT40,
  AudiQ5CT70,
  AudiQ5RT70,
  MazdaCX5CT05,
  MazdaCX5CT20,
  MazdaCX5CT40,
  MazdaCX5CT70,
  MazdaCX5RT70,
  MercedesCT05,
  MercedesCT20,
  MercedesCT40,
  MercedesCT70,
  MercedesRT70,
  MitsubishiCT05,
  MitsubishiCT20,
  MitsubishiCT40,
  MitsubishiCT70,
  MitsubishiRT70,
  VWSciroccoCT05,
  VWSciroccoCT20,
  VWSciroccoCT40,
  VWSciroccoCT70,
  VWSciroccoRT70,
} from '../../images';

export const imageRender = (setImageCar, typeFilm, typeCar) => {
  if (typeFilm === 'RT70' && typeCar === 'Mazda CX5') {
    setImageCar(MazdaCX5RT70);
  }
  if (typeFilm === 'CT70' && typeCar === 'Mazda CX5') {
    setImageCar(MazdaCX5CT70);
  }
  if (typeFilm === 'CT40' && typeCar === 'Mazda CX5') {
    setImageCar(MazdaCX5CT40);
  }
  if (typeFilm === 'CT20' && typeCar === 'Mazda CX5') {
    setImageCar(MazdaCX5CT20);
  }
  if (typeFilm === 'CT05' && typeCar === 'Mazda CX5') {
    setImageCar(MazdaCX5CT05);
  }

  if (typeFilm === 'RT70' && typeCar === 'Mercedes') {
    setImageCar(MercedesRT70);
  }
  if (typeFilm === 'CT70' && typeCar === 'Mercedes') {
    setImageCar(MercedesCT70);
  }
  if (typeFilm === 'CT40' && typeCar === 'Mercedes') {
    setImageCar(MercedesCT40);
  }
  if (typeFilm === 'CT20' && typeCar === 'Mercedes') {
    setImageCar(MercedesCT20);
  }
  if (typeFilm === 'CT05' && typeCar === 'Mercedes') {
    setImageCar(MercedesCT05);
  }

  if (typeFilm === 'RT70' && typeCar === 'Mitsubishi') {
    setImageCar(MitsubishiRT70);
  }
  if (typeFilm === 'CT70' && typeCar === 'Mitsubishi') {
    setImageCar(MitsubishiCT70);
  }
  if (typeFilm === 'CT40' && typeCar === 'Mitsubishi') {
    setImageCar(MitsubishiCT40);
  }
  if (typeFilm === 'CT20' && typeCar === 'Mitsubishi') {
    setImageCar(MitsubishiCT20);
  }
  if (typeFilm === 'CT05' && typeCar === 'Mitsubishi') {
    setImageCar(MitsubishiCT05);
  }

  if (typeFilm === 'RT70' && typeCar === 'VW Scirocco') {
    setImageCar(VWSciroccoRT70);
  }
  if (typeFilm === 'CT70' && typeCar === 'VW Scirocco') {
    setImageCar(VWSciroccoCT70);
  }
  if (typeFilm === 'CT40' && typeCar === 'VW Scirocco') {
    setImageCar(VWSciroccoCT40);
  }
  if (typeFilm === 'CT20' && typeCar === 'VW Scirocco') {
    setImageCar(VWSciroccoCT20);
  }
  if (typeFilm === 'CT05' && typeCar === 'VW Scirocco') {
    setImageCar(VWSciroccoCT05);
  }

  if (typeFilm === 'RT70' && typeCar === 'Audi') {
    setImageCar(AudiQ5RT70);
  }
  if (typeFilm === 'CT70' && typeCar === 'Audi') {
    setImageCar(AudiQ5CT70);
  }
  if (typeFilm === 'CT40' && typeCar === 'Audi') {
    setImageCar(AudiQ5CT40);
  }
  if (typeFilm === 'CT20' && typeCar === 'Audi') {
    setImageCar(AudiQ5CT20);
  }
  if (typeFilm === 'CT05' && typeCar === 'Audi') {
    setImageCar(AudiQ5CT05);
  }
};
