import React from 'react';
import { Img, SimpleGrid } from '@chakra-ui/react';
import { CT05, CT20, CT40, CT70, RT70 } from '../../images';

const TypeFilm = ({ setTypeFilm }) => {
  return (
    <SimpleGrid columns={5} width="80" spacing={'4'}>
      <Img
        alt={RT70}
        src={RT70}
        borderRadius="10px"
        width="70px"
        cursor="pointer"
        onClick={() => setTypeFilm('RT70')}
      />
      <Img
        alt={CT70}
        src={CT70}
        borderRadius="10px"
        width="70px"
        cursor="pointer"
        onClick={() => setTypeFilm('CT70')}
      />
      <Img
        alt={CT40}
        src={CT40}
        borderRadius="10px"
        width="70px"
        cursor="pointer"
        onClick={() => setTypeFilm('CT40')}
      />
      <Img
        alt={CT20}
        src={CT20}
        borderRadius="10px"
        width="70px"
        cursor="pointer"
        onClick={() => setTypeFilm('CT20')}
      />
      <Img
        alt={CT05}
        src={CT05}
        borderRadius="10px"
        width="70px"
        cursor="pointer"
        onClick={() => setTypeFilm('CT05')}
      />
    </SimpleGrid>
  );
};

export default TypeFilm;
