import React from 'react';
import { Brands, Hero } from '../../components';

const Home = () => {
  return (
    <>
      <Hero />
      <Brands />
    </>
  );
};

export default Home;
