import React, { useState, useEffect } from 'react';
import { Box, Img, Flex, Select } from '@chakra-ui/react';
import { imageRender, carOption } from '../../utils';
import { MitsubishiRT70 } from '../../images';
import TypeFilm from '../TypeFilm';

const Simulation = () => {
  const [typeFilm, setTypeFilm] = useState('RT70');
  const [imageCar, setImageCar] = useState(MitsubishiRT70);
  const [typeCar, setTypeCar] = useState('Mitsubishi');

  const handleChange = (event) => {
    setTypeCar(event.target.value);
    setTypeFilm('RT70');
  };

  useEffect(() => {
    imageRender(setImageCar, typeFilm, typeCar);
  }, [typeFilm, typeCar]);

  return (
    <Box>
      <Flex justify="center" py="10">
        <Select width="600px" value={typeCar} onChange={handleChange}>
          {carOption.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Flex>
      <Flex
        className="group"
        cursor="pointer"
        position="relative"
        rounded="lg"
        overflow="hidden"
        justify="center"
      >
        <Img
          alt={imageCar}
          src={imageCar}
          borderRadius="20px"
          width={{ lg: '600px' }}
          height={{ lg: '400px' }}
          objectFit="cover"
        />
      </Flex>
      <Flex justify="center" pt="10">
        <TypeFilm setTypeFilm={setTypeFilm} />
      </Flex>
    </Box>
  );
};

export default Simulation;
