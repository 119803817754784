import React from 'react';
import {
  Box,
  Img,
  Text,
  SimpleGrid,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import {
  AudiLogo,
  MazdaLogo,
  MercedesLogo,
  MitsubishiLogo,
  VolkswagenLogo,
} from '../../images';

const Brands = () => {
  return (
    <Box as="section" py="24">
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
      >
        <Text
          fontWeight="bold"
          fontSize="sm"
          textAlign="center"
          textTransform="uppercase"
          letterSpacing="wide"
          color={mode('gray.600', 'gray.400')}
        >
          Featured Clients
        </Text>
        <SimpleGrid
          mt="8"
          columns={{ base: 1, md: 2, lg: 5 }}
          color="gray.500"
          alignItems="center"
          justifyItems="center"
          spacing={{ base: '12', lg: '24' }}
          fontSize="2xl"
        >
          <Img
            src={MitsubishiLogo}
            alt={MitsubishiLogo}
            width={{ base: '200px' }}
          />
          <Img
            src={MercedesLogo}
            alt={MercedesLogo}
            width={{ base: '200px' }}
          />
          <Img src={MazdaLogo} alt={MazdaLogo} width={{ base: '200px' }} />
          <Img
            src={VolkswagenLogo}
            alt={VolkswagenLogo}
            width={{ base: '200px' }}
          />
          <Img src={AudiLogo} alt={AudiLogo} width={{ base: '200px' }} />
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Brands;
